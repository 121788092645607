<template>
  <v-container>
    <v-row class="mx-0 mb-2">
      <div style="width: 12rem !important;">
      <v-select
          v-if="
            checkReadPermission($modules.facility.schedule.slug)
          "
          class="no-right-border"
          :items="venueServices"
          v-model="venueService"
          item-value="venue_service_id"
          item-text="name"
          outlined
          :menu-props="{ bottom: true, offsetY: true }"
          background-color="white"
          hide-details
          label="Service"
          dense
          flat
          @change="loadVenueServiceConfiguration()"
      ></v-select>
      </div>
      <v-spacer></v-spacer>
      <BackButton :handler="gotoSchedule"/>

    </v-row>
    <h3 class="mb-2">Schedule Layout Configuration</h3>
    <v-card outlined class="elevation-0">
      <v-row class="pa-5 ma-1">
        <v-radio-group class="benefit_type_radio"
                       v-model="configuration.schedule_page_configuration"
                       row
        >
          <v-radio
              label="Service Based"
              value="service"
              class="custom-radio"
              color="cyan"
          />
          <v-radio
              label="Facility Based"
              value="facility"
              class="custom-radio"
              color="cyan"
          />
        </v-radio-group>
      </v-row>
    </v-card>

    <div v-if="configuration.schedule_page_configuration === 'facility'">
      <h3 class="mb-2 mt-5">Week Configuration</h3>
      <v-card outlined class="elevation-0">
        <v-row class="pa-5 ma-1">
          <v-radio-group class="benefit_type_radio"
                         v-model="configuration.week_configuration"
                         row
          >
            <v-radio
                label="Start with Today"
                value="current_date"
                class="custom-radio"
                color="cyan"
            />
            <v-radio
                label="Sunday to Saturday"
                value="sunday_saturday"
                class="custom-radio"
                color="cyan"
            />
          </v-radio-group>
        </v-row>
      </v-card>
    </div>

    <h3 class="mt-6 mb-2">Timing Configuration</h3>
    <v-card outlined class="elevation-0">
      <v-row class="pa-5 ma-1">
        <v-col xs="12" sm="6" md="3">
          <v-select
              label="Time Increment"
              required
              v-model="
                configuration.time_increment
              "
              hint="Time increment for schedule slots"
              :items="[
                { text: '5 min', value: 5 },
                { text: '10 min', value: 10 },
                { text: '15 min', value: 15 },
                { text: '20 min', value: 20 },
                { text: '30 min', value: 30 },
                { text: '40 min', value: 40 },
                { text: '45 min', value: 45 },
                { text: '1 Hour', value: 60 },
                { text: '1 Hour 30 min', value: 90 },
                { text: '2 Hour', value: 120 },
                { text: '2 Hour 30 min', value: 150 },
                { text: '3 Hour', value: 180 },
                { text: '3 Hour 30 min', value: 210 },
                { text: '4 Hour', value: 240 },
              ]"
              @change="changeTimeIncrement"
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              background-color="#fff"
          ></v-select>
        </v-col>
        <v-col xs="12" sm="6" md="3">
          <v-select
              label="Min booking time"
              required
              :readonly="
                !configuration.time_increment ||
                  configuration.per_capacity === 1
              "
              v-model="
                configuration.min_booking_time
              "
              hint="Min booking time"
              item-text="text"
              item-value="value"
              :items="getMinBookingTimes()"
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              background-color="#fff"
          ></v-select>
        </v-col>
      </v-row>
    </v-card>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          class="ma-2 white--text blue-color"
          text
          color="white"
          height="45"
          @click="saveConfiguration"
      >Save</v-btn
      >
    </v-card-actions>
  </v-container>
</template>

<script>
import BackButton from "@/components/Common/BackButton.vue";

export default {
  components: {BackButton},
  props: {
    venueServiceId:{type:Number, default:null}
  },
  data(){
    return {
      venueService:null,
      configuration:{
        schedule_page_configuration:'service',
        week_configuration:'current_date',
        min_booking_time:60,
        time_increment:60,
        per_capacity:0,
        product_type:"Facility"
      }
    }
  },
  computed:{
    venueServices() {
      return this.$store.getters.getSportsService.filter( (service) => service.name != "POS");
    },
    venueServiceConfiguration() {
      return this.$store.getters.getConfigurationByVenueServiceId(
          this.venueService ? this.venueService :this.venueServiceId
      );
    },
  },
  mounted() {
    if (typeof this.$route.params.data != "undefined") {
      let id = parseInt(atob(this.$route.params.data));
      this.venueService = id;
    }
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then( () => {
        this.loadVenueServiceConfiguration();
      });
    }else{
        this.loadVenueServiceConfiguration();
    }


  },
  methods:{
    loadVenueServiceConfiguration(){
      console.log(this.venueService)
        this.$store.dispatch(
                "loadConfigurationsByVenueServiceId",
                this.venueService ? this.venueService :this.venueServiceId
            ).then(() => {
          this.getVenueServiceConfiguration();
        });
    },
    getVenueServiceConfiguration(){
        this.configuration.schedule_page_configuration = this.venueServiceConfiguration.schedule_page_configuration;
        this.configuration.week_configuration = this.venueServiceConfiguration.week_configuration;
        this.configuration.min_booking_time = this.venueServiceConfiguration.min_booking_time;
        this.configuration.time_increment = this.venueServiceConfiguration.time_increment;
        this.configuration.per_capacity = this.venueServiceConfiguration.per_capacity;
      // else{
      //   this.loadVenueServiceConfiguration();
      // }
    },
    gotoSchedule() {
      this.$router.push({
        name: "Schedule",
      });
    },
    saveConfiguration(){
      this.showLoader('Saving...');
      let data = this.configuration;
      this.$http
          .post(`venues/configurations/schedule/${this.venueService}`, data)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.showSuccess("Configuration saved");
              this.hideLoader();
              this.$store.dispatch(
                  "loadConfigurationsByVenueServiceId",
                  this.venueService
              ).then(() => {
                this.gotoSchedule();
              })
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });

    },
    changeTimeIncrement() {
      if (this.configuration.per_capacity === 1) {
        this.configuration.min_booking_time = this.configuration.time_increment;
      }
    },
    getMinBookingTimes() {
      let times = [];
      const timeIncrement = this.configuration.time_increment;
      let hour = parseInt(timeIncrement / 60);
      if (timeIncrement) {
        let index = 1;
        while (hour < 24) {
          let time = timeIncrement * index;
          hour = parseInt(time / 60);
          let min = time / 60 - hour > 0 ? (time / 60 - hour) * 60 : 0;
          let text = hour > 0 ? `${hour} Hour ` : "";
          text += min > 0 ? `${Math.round(min)} Min` : "";
          times.push({ value: time, text: text });
          index++;
        }
      }
      return times;
    },
  },
}
</script>
<style scoped>
.card{

}
</style>